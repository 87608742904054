import ajax from './ajax'
// const BASE_URL = 'http://50.50.21.7/api'
// const BASE_URL = "http://"+window.location.host
// const BASE_URL = 'http://www.yqs.com/admin'
const BASE_URL = 'http://green.sztopmax.com.cn';
export const getTop = (domain) => ajax(BASE_URL + '/index/ajax_scan',{method:'post',body:{domain:domain,isTop:1}})
export const getImgList = (domain) => ajax(BASE_URL + '/other/getImgList',{method:'get',body:{domain:domain}})
export const getHtmlList = (domain) => ajax(BASE_URL + '/other/getHtmlList',{method:'post',body:{domain:domain}})
export const getParent = (domain,dir) => ajax(BASE_URL + '/index/ajax_scan',{method:'post',body:{domain:domain,dir:dir}})
export const CheckImage = (domain,content) => ajax(BASE_URL + '/index/Checking',{method:'post',body:{domain:domain,content:content,type:"1"}});
export const GetResult = (domain) => ajax(BASE_URL + '/index/GetResult',{method:'post',body:{domain:domain}})
export const getHtml = (domain) => ajax(BASE_URL + '/index/GetHtmlList',{method:'post',body:{domain:domain}})
export const CheckText = (domain,content) => ajax(BASE_URL + '/index/Checking',{method:'post',body:{domain:domain,content:content,type:"2"}});
export const Checking = (domain,content,type) => ajax(BASE_URL + '/other/Checking',{method:'get',body:{domain:domain,content:content,type:type}});
