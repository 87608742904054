import {ajax} from 'jquery'
export default function request(
  url,
  option,
) {
  const options = {
    ...option,
  };
  return ajax({
    type: (options.method) ? options.method : 'post',
    url: url,
    data: options.body,
    beforeSend:function() {

    },
    dataType: 'json',
    success: function(response) {
      if(response.status==-100){
        localStorage.removeItem('Authorization');
        window.location.hash = '/login';
      }
      return response;
    },
    error: function(err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  })};
